
import {
    createVNode
} from 'vue';
import {
    ExclamationCircleOutlined,
    DeleteOutlined,
    MailOutlined,
    PhoneOutlined,
    DownOutlined,
    PlusOutlined,
    DownloadOutlined,
    CheckOutlined
} from '@ant-design/icons-vue';
import Editor from './editor';
import ChargeGroup from './charge_group';
import {
    base64ToBlob
} from '@/utils/common';

export default {
    name: "BasicUser",
    components: {
        DeleteOutlined,
        MailOutlined,
        PhoneOutlined,
        DownOutlined,
        PlusOutlined,
        DownloadOutlined,
        CheckOutlined,
        Editor,
        ChargeGroup
    },
    data() {
        return {
            url: '/admin/user/list',
            // 表格搜索条件
            where: {},
            // 表格选中数据
            selection: [],
            // 当前编辑数据
            current: null,
            // 是否显示编辑弹窗
            showEdit: false,
            // 是否显示费用组弹窗
            showChargeGroupEdit: false,
            // 导出
            exportLoading: false,
            // 业务员列表
            salesman_list: [],
            // 会员类型
            userTypes: []
        };
    },
    computed: {
        columns() {
            const add = [];
            add.push({
                title: this.$t('业务员'),
                dataIndex: 'salesman_id',
                width: 150,
                sorter: true,
                customRender: ({
                    text
                }) => {
                    return this.salesman_list.find(i => i.value == text)?.text || ''
                }
            }, )

            return [{
                    title: this.$t('会员代号'),
                    dataIndex: 'code',
                    width: 100,
                    sorter: true
                },
                {
                    title: this.$t('登录账号'),
                    dataIndex: 'account',
                    width: 230,
                    sorter: true
                },
                {
                    title: this.$t('会员类型'),
                    dataIndex: 'type_name',
                    width: 150,
                    sorter: true
                },
                ...add,
                {
                    title: this.$t('公司名称'),
                    dataIndex: 'company_name',
                    width: 200,
                    sorter: true,
                    ellipsis: true
                },
                {
                    title: this.$t('联系人'),
                    dataIndex: 'contact_name',
                    sorter: true,
                    width: 200,
                    slots: {
                        customRender: 'contact'
                    }
                },
                {
                    title: this.$t('信用额度'),
                    dataIndex: 'credit',
                    width: 100,
                    sorter: true,
                    align: 'center'
                },
                {
                    title: this.$t('冻结金额'),
                    dataIndex: 'frozen',
                    width: 100,
                    sorter: true,
                    align: 'center'
                },
                {
                    title: this.$t('账户余额'),
                    dataIndex: 'balance',
                    width: 100,
                    sorter: true,
                    align: 'center',
                    slots: {
                        customRender: 'balance'
                    }
                },
                {
                    title: this.$t('提交订单'),
                    dataIndex: 'allow_submit_order',
                    width: 100,
                    sorter: true,
                    align: 'center',
                    slots: {
                        customRender: 'submit_order'
                    }
                },
                {
                    title: this.$t('系统地址'),
                    dataIndex: 'allow_default_shipper',
                    width: 120,
                    sorter: true,
                    align: 'center',
                    slots: {
                        customRender: 'default_shipper'
                    }
                },
                {
                    title: this.$t('创建发货人'),
                    dataIndex: 'allow_custom_shipper',
                    width: 120,
                    sorter: true,
                    align: 'center',
                    slots: {
                        customRender: 'custom_shipper'
                    }
                },
                {
                    title: this.$t('启用'),
                    dataIndex: 'enabled',
                    width: 80,
                    sorter: true,
                    align: 'center',
                    slots: {
                        customRender: 'status'
                    }
                },
                {
                    title: this.$t('注册时间'),
                    dataIndex: 'created',
                    width: 150,
                    sorter: true
                },
                {
                    title: this.$t('操作'),
                    key: 'action',
                    width: 120,
                    align: 'center',
                    fixed: "right",
                    slots: {
                        customRender: 'action'
                    }
                }
            ]
        }
    },
    methods: {
        /**
         * 获取会员类型
         */
        loadDataUserType() {
            this.$http.get("/admin/role/simple_list/user").then(res => {
                if (res.data.code === 0) {
                    this.userTypes = res.data.data.map(i => {
                        return {
                            value: i.value,
                            label: i.text
                        }
                    });
                } else {
                    this.$message.error(res.data.msg)
                }
            })
        },
        /* 搜索 */
        reload() {
            this.selection = [];
            this.$refs.table.reload({
                page: 1
            });
        },
        /*  重置搜索 */
        reset() {
            this.where = {};
            this.$nextTick(() => {
                this.reload();
            });
        },
        /* 打开编辑弹窗 */
        openEdit(row) {
            this.current = row;
            this.showEdit = true;
        },
        /* 删除单个 */
        remove(row) {
            const hide = this.$message.loading('请求中...', 0);

            this.$http.post('/admin/user/delete/', {
                ids: [row.id]
            }).then(res => {
                hide();
                if (res.data.code === 0) {
                    this.$message.success(res.data.msg);
                    this.reload();
                } else {
                    this.$message.error(res.data.msg);
                }
            }).catch(e => {
                hide();
                this.$message.error(e.message);
            });
        },
        /* 批量删除 */
        removeBatch() {
            if (!this.selection.length) {
                return this.$message.error('请至少选择一条数据');
            }

            this.$confirm({
                title: this.$t('提示'),
                content: this.$t('确定要删除选中的数据吗?'),
                icon: createVNode(ExclamationCircleOutlined),
                maskClosable: true,
                onOk: () => {
                    const hide = this.$message.loading('请求中...', 0);

                    this.$http.post('/admin/user/delete/', {
                        ids: this.selection.map(d => d.id)
                    }).then(res => {
                        hide();
                        if (res.data.code === 0) {
                            this.$message.success(res.data.msg);
                            this.reload();
                        } else {
                            this.$message.error(res.data.msg);
                        }
                    }).catch(e => {
                        hide();
                        this.$message.error(e.message);
                    });
                }
            });
        },
        /* 重置密码 */
        resetPsw(row) {
            this.$confirm({
                title: this.$t('提示'),
                content: this.$t("是否确定重置改用户的密码为'123456'?"),
                icon: createVNode(ExclamationCircleOutlined),
                maskClosable: true,
                onOk: () => {
                    const hide = this.$message.loading('请求中...', 0);

                    this.$http.get(`/admin/user/reset_password/${row.id}?password=` + '123456').then(res => {
                        hide();
                        if (res.data.code === 0) {
                            this.$message.success(res.data.msg);
                        } else {
                            this.$message.error(res.data.msg);
                        }
                    }).catch(e => {
                        hide();
                        this.$message.error(e.message);
                    });
                }
            });
        },
        /* 修改状态 */
        editState(checked, row, field) {
            this.$http.get(`/admin/user/state/${row.id}?${field}=${(checked ? 1 : 2)}`).then(res => {
                if (res.data.code === 0) {
                    row[field] = checked;
                } else {
                    this.$message.error(res.data.msg);
                }
            }).catch(e => {
                this.$message.error(e.message);
            });
        },
        /* 下拉菜单点击事件 */
        dropClick(command, record) {
            if (command === 'remove') { // 删除
                this.$confirm({
                    title: this.$t('提示'),
                    content: this.$t('确定要删除选中的数据吗?'),
                    icon: createVNode(ExclamationCircleOutlined),
                    maskClosable: true,
                    onOk: () => this.remove(record)
                });
            } else if (command === 'resetPsw') {
                this.resetPsw(record);
            } else if (command === 'chargeGroup') {
                this.current = record;
                this.showChargeGroupEdit = true;
                // 登录客户端
            } else if (command === 'loginUser') {
                // 获取当前token
                const adminToken = localStorage.getItem("access_token");

                // 异步获取会员token
                this.$http.get(`/admin/user/create_token/${record.id}`).then(res => {
                    if (res.data.code == 0) {
                        localStorage.setItem('token_collect', JSON.stringify([adminToken, `${res.data.data.token_type} ${res.data.data.access_token}`]));

                        this.$store.dispatch("user/setToken", {
                            token: `${res.data.data.token_type} ${res.data.data.access_token}`,
                            remember: true
                        }).then(() => {
                            location.replace('/'); // 这样跳转避免再次登录重复注册动态路由
                        })
                    } else {
                        this.$message.error(res.data.msg);
                    }
                })
            } else if (command === 'resetBalance') {
                this.resetBalance(record);
            }
        },
        // 余额更正
        resetBalance(row) {
            const hide = this.$message.loading('请求中...', 0);

            this.$http.post(`/admin/user/charges_corrections/${row.id}`).then(res => {
                if (res.data.code == 0) {
                    this.reload();
                    this.$message.success(res.data.msg)
                } else {
                    this.$message.error(res.data.msg)
                }
            }).finally(() => {
                hide();
            })
        },
        /**
         * 导出Excel
         */
        exportExcel() {
            this.exportLoading = true;

            this.$http
                .get(`/admin/user/export`, {
                    params: this.where
                })
                .then(res => {
                    this.exportLoading = false;

                    if (res.data.code === 0) {
                        const blob = base64ToBlob(
                            res.data.data,
                            "application/vnd.ms-excel"
                        );
                        const fileName = `Charges-${new Date().getTime()}.xls`;

                        if ("download" in document.createElement("a")) {
                            // 非IE下载
                            const el = document.createElement("a");

                            el.download = fileName;
                            el.style.display = "none";
                            el.href = URL.createObjectURL(blob);
                            document.body.appendChild(el);

                            el.click();

                            URL.revokeObjectURL(el.href);
                            document.body.removeChild(el);
                        } else {
                            // IE10+下载
                            navigator.msSaveBlob(blob, fileName);
                        }
                    } else {
                        this.$message.error(res.data.msg);
                    }
                })
                .catch(e => {
                    this.exportLoading = false;
                    this.$message.error(e.message);
                });
        },
        /**
         * 获取业务员
         */
        getSalesmanList() {
            this.$http.get("/admin/admin/list_salesman").then(res => {
                if (res.data.code === 0) {
                    this.salesman_list = res.data.data
                }
            })
        }
    },
    async mounted() {
        this.loadDataUserType();
        this.getSalesmanList();
    }
}
